import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorSchedule } from "../models/DoctorSchedule";  

export default {
  GetListByUser2() {
    return AuthAxios.get(ApiURIs.DrScheduleListByUser);
  },
  GetListByChamber() {
    return AuthAxios.get(ApiURIs.DrScheduleListByChamber);
  },
  GetWeeklyScheduleList() {
    return AuthAxios.get(ApiURIs.DrChamberScheduleWeeklyListById);
  },
  GetScheduleList(
    chamberId: number,
    startDate: Date,
  ) {
    return AuthAxios.get(
      `${
        ApiURIs.DrChamberScheduleList
      }?chamberId=${chamberId}&startDate=${startDate.toISOString()}`
    );
  },
  GetScheduleListById(
    doctorId:number,
    chamberId: number,
    startDate: Date,
  ) {
    return AuthAxios.get(
      `${
        ApiURIs.DrChamberScheduleListById
      }?doctorId=${doctorId}&chamberId=${chamberId}&startDate=${startDate.toISOString()}`
    );
  },


  GetScheduleListByDate(
    chamberId: number,
    startDate: Date,
  ) {
    return AuthAxios.get(
      `${
        ApiURIs.DrChamberScheduleListByDate
      }?chamberId=${chamberId}&startDate=${startDate.toISOString()}`
    );
  },

  insert(schedule: IDoctorSchedule) {
    return AuthAxios.post(ApiURIs.DrChamberScheduleInsert, JSON.stringify(schedule));
  },
  UpdateSchedule(schedule: IDoctorSchedule) {
    return AuthAxios.post(ApiURIs.DrChamberScheduleUpdate, JSON.stringify(schedule));
  },
  getDelete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrScheduleDelete}/${id}`);
  },
  GetListByUser(chamberId: number) {
    return AuthAxios.get(
      `${ApiURIs.DrScheduleListByUser}?chamberId=${chamberId}`
    );
  },

};
